export enum LocalStorageKeys {
  IS_AUTOMATIC_MODE = 'automaticMode',
  COLORS = 'colors',
  DEALING_ROUND = 'dealingRound',
  DEALT_CARDS_IN_ROUND = 'dealtCardInRound',
  DEALING_SIGNALS = 'dealingSignals',
  NEXT_BETTING_ROUND = 'nextBettingRound',
  EVENT_ID = 'eventId',
  LOGS = 'logs',
  GAME_STATE = 'gameState',
  SUCCESS_SIGNAL_SENT = 'successSignalSent',
  COM_DEVICE_CONFIG = 'comDeviceConfig',
  IS_READING = 'isReading',
  SELECTED_CARD_READER_TYPE = 'selectedCardReaderType',
  IS_CARD_READER_CONFIGURED = 'isCardReaderConfigured',
  IS_CARD_READER_VALIDATION_DONE = 'isCardReaderValidationDone',
  ID_TOKEN = 'id_token',
  LIVE_WAIT_CONFIG = 'live-wait-config',
  LDH_LAST_HAND_NO = 'ldh-last-hand-no',
  LDH_LAST_BET_ROUND = 'ldh-last-bet-round',
  LDH_LAST_SQUEEZE_ROUND = 'ldh-last-squeeze-round',
  LDH_NEXT_SQUEEZE_ROUND = 'ldh-next-squeeze-round',
  LDH_HAS_INTRO_RUN = 'ldh-has-intro-run',
  LDH_HAS_OUTRO_RUN = 'ldh-has-outro-run',
  LDH_ACTIVE_POSITION = 'ldh-active-position',
  LDH_LAST_ACTIVE_POSITION = 'ldh-last-active-position',
}

export const getFromLocalStorage = <T>(key: string, defaultValue: T | null = null): T | null => {
  const lsValue = localStorage.getItem(key);

  if (lsValue) {
    try {
      const parsedValue: T = JSON.parse(lsValue);
      return parsedValue;
    } catch (jsonError) {
      console.error(`Error parsing local storage value as JSON for key ${key}:`, jsonError);
    }
  }

  return defaultValue;
};

export const deleteFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const saveToLocalStorage = (key: string, newValue: any) => {
  localStorage.setItem(key, JSON.stringify(newValue));
};
